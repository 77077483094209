/* ========================================================================
 * DOM-based Routing
 * Based on https://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //Menu
        var navWrapperHeight = $('.jc-nav-wrapper').height();
        var catapultCookieBar = 0;

        $('#catapult-cookie-bar').css({zIndex:1000000, top: '44px'});
        $(window).resize(function() {
          if( $('#catapult-cookie-bar').length ){

            catapultCookieBar = $('#catapult-cookie-bar').outerHeight();
            $('.has-cookie-bar.cookie-bar-top-bar.cookie-bar-bar').css('margin-top',catapultCookieBar);

            $( "#catapultCookie" ).click(function() {
              $('.jc-navbar-wrapper').css('top', (navWrapperHeight + 38));
            });
          }
        });

        $('#jcMenu').on('show.bs.modal', function () {

          if( $('#catapult-cookie-bar').length ){
            catapultCookieBar = $('#catapult-cookie-bar').outerHeight();
          }

          $('.jc-nav-icon').addClass('open');
          $('.jc-navbar-btn-search').addClass('jc-hide');
          $('.jc-navbar-wrapper').css('top', (navWrapperHeight + catapultCookieBar + 38));


          // Set focus
          setTimeout(function (){
            $('input#busca').focus().select();
          }, 100);
        });

        $('#jcMenu').on('hide.bs.modal', function (e) {
          $('.jc-nav-icon').removeClass('open');
          $('.jc-navbar-btn-search').removeClass('jc-hide');
        });

        //Show mobile share bar

        $(window).scroll(function(){
          var postHeight = $('.jc-post-body').height();
          var postOffset = $('.jc-post-body').offset();
          var postScrollTop = $('.jc-post-body').scrollTop() + 100;
          var st = $(this).scrollTop();

          if (st > postScrollTop && st < (postScrollTop + postHeight)){
            $('.jc-social-media-share-mobile').addClass('show');
          } else {
            $('.jc-social-media-share-mobile').removeClass('show');
          }
        });

        // Get scrollbar width

        function getScrollBarWidth() {
          var inner = document.createElement('p');
          inner.style.width = "100%";
          inner.style.height = "200px";

          var outer = document.createElement('div');
          outer.style.position = "absolute";
          outer.style.top = "0px";
          outer.style.left = "0px";
          outer.style.visibility = "hidden";
          outer.style.width = "200px";
          outer.style.height = "150px";
          outer.style.overflow = "hidden";
          outer.appendChild (inner);

          document.body.appendChild (outer);
          var w1 = inner.offsetWidth;
          outer.style.overflow = 'scroll';
          var w2 = inner.offsetWidth;
          if (w1 === w2) {
            w2 = outer.clientWidth;
          }

          document.body.removeChild (outer);

          return (w1 - w2);
        }

        //Ajust position jc-nav open

        $('#jcMenu').on('hidden.bs.modal', function (e) {
          $(".jc-nav").css("padding-right", 0);
          $(".OESP__barra").css("padding-right", 0);
        });

        $('#jcMenu').on('show.bs.modal', function (e) {
          $(".jc-nav").css("padding-right", getScrollBarWidth());
          $(".OESP__barra").css("padding-right", getScrollBarWidth());
        });

        //trata a responsividade de iframe de youtube dentro de um post
        var iframeYoutube = $('.jc-post-content p iframe[src*="youtube"]');

        if( iframeYoutube.length ){
          iframeYoutube.css({'position': 'absolute', 'top': '0', 'left': '0', 'width': '100%', 'height': '100%'});
          iframeYoutube.parent().css({'position': 'relative', 'padding-bottom': '56.25%', 'padding-top': '30px', 'overflow': 'hidden', 'height': '0'});
        }

        //Primeiro item do menu clicável

        $('.jc-second-nav .dropdown > a').click(function(){
          location.href = this.href;
        });

        //Tabs btn-group

        $('.btn-group .btn').on('click', function(e) {
          e.preventDefault();
          $(this)
          .addClass('active')
          .tab('show')
          .siblings('.btn')
          .removeClass('active');
        });

        //Accordion

        $('.collapse').on('hide.bs.collapse', function () {
          var id = $(this).attr('id');
          var target = '[data-target="#'+id+'"]';

          $(target).children('i').removeClass('jc-accordion-open');
        });

        $('.collapse').on('show.bs.collapse', function () {
          var id = $(this).attr('id');
          var target = '[data-target="#'+id+'"]';

          $(target).children('i').addClass('jc-accordion-open');
        });

        //Mobile sidebar

        $(document).on('click', '[data-toggle="mobileSidebar"]', function(e){
          var target = $(this).data('target');

          $(target).addClass('jc-open');
          $("body").addClass("modal-open");
          $('<div class="modal-backdrop fade"></div>').insertAfter(target);
          setTimeout(function() {
            $('.modal-backdrop').addClass('in');
          }, 140);

          $('.modal-backdrop').click(function(){
            $(target).removeClass('jc-open');
            $('.modal-backdrop').removeClass('in');
            setTimeout(function() {
              $('.modal-backdrop').remove();
            }, 140);
            $("body").removeClass("modal-open");
          });
        });

        $(document).on('click', '[data-dismiss="mobileSidebar"]', function(e){
          var target = $(this).data('target');

          $(target).removeClass('jc-open');
          $('.modal-backdrop').removeClass('in');
          setTimeout(function() {
            $('.modal-backdrop').remove();
          }, 140);
          $("body").removeClass("modal-open");
        });


        //Stick filter-result-bar

        var barHeight = $('.jc-filter-result-menu').height();
        var barOffset = $('.jc-filter-result-menu');
        if (barOffset.lenght) {
          barOffset.offset().top(barHeight - 20);
        }

        $(window).scroll(function(){
          var windowScroll = $(this).scrollTop();

          if (windowScroll > barOffset) {
            $('.jc-filter-result').addClass('jc-stick');
            $('.jc-filter-result-menu').addClass('jc-stick');
          } else {
            $('.jc-filter-result').removeClass('jc-stick');
            $('.jc-filter-result-menu').removeClass('jc-stick');
          }

        });

        //Gráfico

        $('.progress-pie-chart').each(function(){
          var $ppc = $(this) ,
          percent = parseInt($ppc.data('percent')),
          deg = 360*percent/80;
          if (percent > 50) {
            $ppc.addClass('gt-50');
          }
          $($ppc).find('.ppc-progress-fill').css('transform','rotate('+ deg +'deg)');
          $($ppc).find('.ppc-percents span').html(percent+'/80');
        });


        //Slide listas guia de compra

        $('.jc-shopping-list-slide').slick({
          dots: true,
          lazyLoad: 'ondemand',
          infinite: true,
          slidesToShow: 7,
          slidesToScroll: 7,
          responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 6
            }
          },
          {
            breakpoint: 1020,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              arrows: false,
              dots: true
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: false,
              dots: true
            }
          }
          ]
        });

        //Card slider

        $('.jc-card-slider').slick({
          infinite: true,
          dots: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              dots: true
            }
          }
          ]

        });


        //Tabs responsivas

        function collapseTab(tabs){
          var children = tabs.children('li:not(:last-child)'),
          count = children.size(),
          item = $(children[count-1]);

          item.find('a').addClass('dropdown-item').removeClass('nav-link').prependTo('#collapsed');
          item.remove();

        }

        function uncollapseTab(tabs) {
          var collapsed = $('#collapsed').children('.dropdown-item'),
          coll = $(collapsed[0]).removeClass('dropdown-item').addClass('nav-link'),
          lastTabItem = tabs.children('li:last-child'),
          tabItem = $('<li class="nav-item"></li>'),
          newItem = tabItem.append(coll);

          newItem.insertBefore(lastTabItem);
        }

        var autocollapse = function() {
          var tabs = $('#shopping-guide'),
          tabsHeight = tabs.innerHeight(),
          tabHeight = tabs.find('.nav-item:first-child').innerHeight(),
          collapsed = $('#collapsed').children('.dropdown-item'),
          count = collapsed.size();

          if (tabsHeight > tabHeight) {
            while(tabsHeight > tabHeight) {
              collapseTab(tabs);
              tabsHeight = tabs.innerHeight();
              count++;
            }
          } else {
            while(count > 0 && tabsHeight <= tabHeight) {
              uncollapseTab(tabs);
              tabsHeight = tabs.innerHeight();
              count--;
            }

            if(tabsHeight > tabHeight) {
              collapseTab(tabs);
              count++;
            }
          }

          if (count < 1) {
            $('#lastTab').hide();
          } else {
            $('#lastTab').show();
          }
        };

        $('.nav-tabs').on('shown.bs.tab', 'a', function (e) {
          if (e.relatedTarget) {
            $(e.relatedTarget).removeClass('active');
          }
        });

        $(window).on('load resize', autocollapse);


        //Resize de vídeos no corpo do texto

        var $allVideos = $("iframe[src*='youtube.com']"),
            $fluidEl = $(".jc-post-body");

            if ($fluidEl.length > 0) {
              $allVideos.each(function() {

                $(this)
                .data('aspectRatio', this.height / this.width)
                  .removeAttr('height')
                  .removeAttr('width');
                });

              $(window).resize(function() {

                var newWidth = $fluidEl.width();

                // Resize all videos according to their own aspect ratio
                $allVideos.each(function() {

                  var $el = $(this);
                  $el
                  .width(newWidth)
                  .height(newWidth * $el.data('aspectRatio'));
                });
              }).resize();
            }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  /*
  * Função criada única e exclusivamente para fechar a publicidade stick - em versões mobile - no clique do botão fechar
  */
  $(document).on('click','.jc-sticky-ad-close-button', function(){
    $(this).parents('.jc-sticky-ad-wrapper').remove();
  });

})(jQuery); // Fully reference jQuery after this point.
