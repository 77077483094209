function getSocialMediaTemplate(shareurl, sharetitle) {
  return (
    '<ul class="jc-social-media-share-list jc-post-thumb-header-item">' +
    '<li class="jc-social-media-share-list-item">' +
    '<a aria-label="Compartilhar no facebook" data-social-type="Facebook" target="_blank" rel="noopener" href="https://www.facebook.com/sharer/sharer.php?u=' +
    shareurl +
    '">' +
    '<i class="fa fa-facebook" aria-hidden="true"></i>' +
    "</a>" +
    "</li>" +
    '<li class="jc-social-media-share-list-item hidden-lg-up">' +
    '<a aria-label="Compartilhar no whatsapp" data-social-type="Whatsapp" href="whatsapp://send?text=' +
    shareurl +
    '">' +
    '<i class="fa fa-whatsapp" aria-hidden="true"></i>' +
    "</a>" +
    "</li>" +
    '<li class="jc-social-media-share-list-item hidden-md-down">' +
    '<a aria-label="Compartilhar no twitter" data-social-type="Twitter" target="_blank" rel="noopener" href="https://twitter.com/intent/tweet?text=' +
    shareurl +
    '">' +
    '<i class="fa fa-twitter" aria-hidden="true"></i>' +
    "</a>" +
    "</li>" +
    '<li class="jc-social-media-share-list-item dropdown">' +
    '<button type="button" aria-label="Compartilhar" class="btn-link" data-toggle="dropdown">' +
    '<i class="fa fa-ellipsis-h" aria-hidden="true"></i>' +
    "</button>" +
    '<div class="dropdown-menu dropdown-menu-right social-media-share-dropdown" aria-labelledby="socialMediaOptions">' +
    '<a class="dropdown-item jc-twitter hidden-lg-up" data-social-type="Twitter" target="_blank" rel="noopener" href="https://twitter.com/intent/tweet?text=' +
    shareurl +
    '">' +
    '<i class="fa fa-twitter" aria-hidden="true"></i>' +
    "<span>Twitter</span>" +
    "</a>" +
    '<a class="dropdown-item jc-google-plus" data-social-type="Google Plus" target="_blank" rel="noopener" href="https://plus.google.com/share?url=' +
    shareurl +
    '">' +
    '<i class="fa fa-google-plus" aria-hidden="true"></i>' +
    "<span>Google +</span></a>" +
    '<a class="dropdown-item jc-linkedin" data-social-type="Linkedin" target="_blank" rel="noopener" href="https://www.linkedin.com/shareArticle?mini=true&url=' +
    shareurl +
    "&title=" +
    sharetitle +
    '&summary=&source=">' +
    '<i class="fa fa-linkedin" aria-hidden="true"></i>' +
    "<span>Linkedin</span></a>" +
    '<a class="dropdown-item" data-social-type="Email" href="mailto:seu@email.com&subject=' +
    sharetitle +
    "&body=" +
    shareurl +
    '">' +
    '<i class="fa fa-envelope-o" aria-hidden="true"></i>' +
    "<span>E-mail</span>" +
    "</a>" +
    '<a class="dropdown-item" data-social-type="Imprimir" href="javascript:window.print()">' +
    '<i class="fa fa-print" aria-hidden="true"></i>' +
    "<span>Imprimir</span>" +
    "</a>" +
    "</div>" +
    "</li>" +
    "</ul>"
  );
}
