
  function loadHomeSection(element) {
    var category  = element.attr('data-category'),
        adTag     = element.attr('data-advertinsing'),
        notin     = element.attr('data-notin'),
        dfpFormat = element.attr('data-dfp-format'),
        deferred  = jQuery.Deferred();

    jQuery.ajax({
      url: ajaxHandler.url,
      type: 'POST',
      data: {
        action: 'jc_ajax_get_home_posts_by_category',
        category: category,
        adTag: adTag,
        notin: notin,
        dfpFormat: dfpFormat
      },
      success: function(response) {
        if(response.data){
          jQuery(element).html(response.data.layout);
          jQuery(element).attr('data-ids',response.data.duplicate);
        }
        else{
        }

        deferred.resolve();
      },
      error: function(data) {
      }
    });

    return deferred.promise();
  }

  function loadMorePosts(element,page) {
    jQuery('.agile-btn-load').css('opacity','1');

    var pageUrl   = element.attr('data-pageurl'),
        pageTitle = element.attr('data-pagetitle'),
        posttype  = element.attr('data-posttype'),
        category  = element.attr('data-category'),
        slug      = element.attr('data-slug'),
        taxonomy  = element.attr('data-taxonomy'),
        template  = element.attr('data-template'),
        notin     = element.attr('data-notin'),
        deferred = jQuery.Deferred();

    jQuery.ajax({
      url: ajaxHandler.url,
      type: 'POST',
      data: {
        action: 'jc_ajax_get_posts',
        page: page,
        posttype : posttype,
        category: category,
        slug: slug,
        taxonomy: taxonomy,
        template: template,
        notin: notin
      },
      success: function(response) {
        jQuery(response.data).appendTo(element);

        dataLayer.push({
          'event':'VirtualPageview',
          'virtualPageURL': pageUrl,
          'virtualPageTitle': pageTitle
        });

        dataLayerEstadao.push({
          'event':'forcePvCheckout',
          'page': pageUrl
        });

        gtag('event', 'VirtualPageview', {
          'virtualPageURL': pageUrl,
          'virtualPageTitle': pageTitle
        });

        if (response.data === undefined) {
          jQuery('.agile-btn-load').css('opacity','0');
        } else {
          jQuery('.agile-btn-load').css('opacity','1');
        }
        deferred.resolve();
      },
      error: function(data) {
      }
    });
    return deferred.promise();
  }


