jQuery(document).ready(function ($) {
  $(window).load(function() {

    /**
   * Cliques em qualquer rede social
   */
    $(".jc-social-media-share-list a").click(function(){

      dataLayerEstadao.push({
        'event': 'genericEvent',
        'genericCategory': 'engajamento',
        'genericAction': $(this).data('social-type'),
        'genericLabel': 'clique'
      });

      gtag('event', 'engajamento', {
        'genericAction': $(this).data('social-type'),
        'genericLabel': 'clique'
      });

  });

  });
});


